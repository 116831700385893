body {
    font: 62.5% 'Roboto', 'Open Sans', Verdana, Arial, Helvetica, sans-serif;
    font-size: 90%;
    color: #1b1c22;
}

h1 {
    font-size: 1.8em;
    font-weight: 300;
    color: #B72B2A;
}

h2 {
    font-size: 1.55em;
    font-weight: 400;
    color: #B72B2A;
}

h3 {
    font-size: 1.4em;
    font-weight: 400;
    color: #B72B2A;
}

h4 {
    font-size: 1.1em;
    font-weight: 400;
}

a {
    color: #7a7a7a;
}